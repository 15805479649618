.contenedor_joinus{
    min-height: 100vh;
    background-size: cover;   
    background-position: center center; 
    background-color: rgb(173, 216, 230);  
}


.imagenesTam{
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.imagenesTam1{
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.table_1{
    border: 1px solid black;
    align: center;
}

.table_2{
    border: 1px solid black;
    align: center;
}

.table_3{
    border: 1px solid black;
    align: center;
    background: #90B1DB;
}

.table_4{
    border: 1px solid black;
    align: center;
    background: white;
}


#imagenajustada{
    width: 100%;
    max-width: 1200px;
    max-height: 400px;


}